<template>
  <v-row>
    <v-col>
      <v-expansion-panels v-model="fund_mort_panels" multiple>
        <!-- General Information -->
        <v-expansion-panel class="bordered">
          <v-expansion-panel-header>
            General Informtaion
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td :width="350">Filogix App Number</td>
                    <td>{{ item.filogix_number }}</td>
                  </tr>

                  <tr>
                    <td>Fiscal Year</td>
                    <td>{{ item.fiscal_year }}</td>
                  </tr>

                  <tr>
                    <td>Brokerage Agreement Signed</td>
                    <td>{{ item.brokerage_agreement_signed | formatDate }}</td>
                  </tr>

                  <tr>
                    <td>Commitment Signed</td>
                    <td>{{ item.commitment_signed | formatDate }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Customers -->
        <v-expansion-panel class="bordered">
          <v-expansion-panel-header>
            Customers
            <span v-if="item.int_custs ? item.int_custs.length === 0 : false">
              (please create deal customers)
            </span>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-expansion-panels multiple>
              <v-expansion-panel
                v-for="int_cust in item.int_custs"
                :key="int_cust.id"
                class="bordered"
              >
                <v-expansion-panel-header>
                  <div class="d-flex align-center">
                    {{ int_cust.cust_snap.first_name }}
                    {{ int_cust.cust_snap.middle_name }}
                    {{ int_cust.cust_snap.last_name }}
                    {{ int_cust.main_applicant ? " (Main Applicant)" : "" }}
                  </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-simple-table>
                    <thead>
                      <th :width="250" />
                      <th />
                    </thead>

                    <tbody>
                      <tr>
                        <td colspan="2" class="grey--text">
                          Contact Information:
                        </td>
                      </tr>

                      <tr>
                        <td>Email</td>

                        <td class="py-2">
                          <div
                            v-for="contact_info in int_cust.cust_snap.contact_infos.filter(
                              (el) => el.type === 'Email'
                            )"
                            :key="contact_info.id"
                          >
                            {{ contact_info.email }}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>Home Phone Number</td>

                        <td class="py-2">
                          <div
                            v-for="contact_info in int_cust.cust_snap.contact_infos.filter(
                              (el) => el.type === 'Home Phone Number'
                            )"
                            :key="contact_info.id"
                          >
                            {{ contact_info.number }}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>Cellphone Number</td>

                        <td class="py-2">
                          <div
                            v-for="contact_info in int_cust.cust_snap.contact_infos.filter(
                              (el) => el.type === 'Cell Phone Number'
                            )"
                            :key="contact_info.id"
                          >
                            {{ contact_info.number }}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>Work Phone Number</td>

                        <td class="py-2">
                          <div
                            v-for="contact_info in int_cust.cust_snap.contact_infos.filter(
                              (el) => el.type === 'Work Phone Number'
                            )"
                            :key="contact_info.id"
                          >
                            {{ contact_info.number }}
                          </div>
                        </td>
                      </tr>

                      <tr v-if="!int_cust.main_applicant">
                        <td colspan="2" class="grey--text">
                          Co-Applicant Information:
                        </td>
                      </tr>

                      <tr v-if="!int_cust.main_applicant">
                        <td>Co-Applicant Type</td>

                        <td>
                          {{ int_cust.co_applicant_type }}
                        </td>
                      </tr>

                      <tr
                        v-if="
                          int_cust.business_for_self ||
                          int_cust.variable_income ||
                          int_cust.poa
                        "
                      >
                        <td colspan="2" class="grey--text">
                          Financial Information:
                        </td>
                      </tr>

                      <tr v-if="int_cust.business_for_self">
                        <td>
                          Bussiness for Self:
                          {{ int_cust.business_for_self | yes_no }}
                        </td>

                        <td>
                          <v-expansion-panel class="my-2 bordered">
                            <v-expansion-panel-header>
                              Bussiness for Self Notes
                            </v-expansion-panel-header>

                            <v-divider />

                            <v-expansion-panel-content class="mt-5">
                              <p
                                v-for="item in int_cust.business_self_notes"
                                :key="item.id"
                              >
                                • {{ item.note }}
                              </p>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </td>
                      </tr>

                      <tr v-if="int_cust.variable_income">
                        <td>
                          Variable Income:
                          {{ int_cust.variable_income | yes_no }}
                        </td>

                        <td>
                          <v-expansion-panel class="my-2 bordered">
                            <v-expansion-panel-header>
                              Variable Income Notes
                            </v-expansion-panel-header>

                            <v-divider />

                            <v-expansion-panel-content class="mt-5">
                              <p
                                v-for="item in int_cust.var_inc_notes"
                                :key="item.id"
                              >
                                • {{ item.note }}
                              </p>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </td>
                      </tr>

                      <tr v-if="int_cust.poa">
                        <td>
                          Power of Attorney:
                          {{ int_cust.poa | yes_no }}
                        </td>

                        <td>
                          <v-expansion-panel class="my-2 bordered">
                            <v-expansion-panel-header>
                              Power of Attorney Notes
                            </v-expansion-panel-header>

                            <v-divider />

                            <v-expansion-panel-content class="mt-5">
                              <p
                                v-for="item in int_cust.poa_notes"
                                :key="item.id"
                              >
                                • {{ item.note }}
                              </p>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <div class="mt-5 text-subtitle-2 grey--text">
              {{
                item.is_blanket_mortgage
                  ? "This is a blanket mortgage"
                  : "This is not a blanket mortgage"
              }}
            </div>

            <div v-show="item.int_props ? item.int_props.length > 0 : false">
              <v-expansion-panels>
                <v-expansion-panel class="mt-5 bordered">
                  <v-expansion-panel-header>
                    Properties
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <p
                      v-if="
                        item.int_props ? item.int_props.length === 0 : false
                      "
                      class="d-flex justify-center grey--text text-subtitle-1"
                    >
                      No properties were found.
                    </p>

                    <v-simple-table
                      v-if="item.int_props ? item.int_props.length > 0 : false"
                    >
                      <thead class="text-caption">
                        <tr>
                          <th :width="80">Subject Property?</th>
                          <th>Owned By</th>
                          <th>Address</th>
                          <th>Type</th>
                          <th>Purchase Price</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="item in item.int_props" :key="item.id">
                          <td>
                            {{ item.subject ? "Yes" : "No" }}
                          </td>

                          <td>
                            <div
                              v-for="(customer, index) in item.prop_snap
                                .customers"
                              :key="customer.id"
                            >
                              • {{ customer.first_name }}
                            </div>
                          </td>

                          <td>
                            {{ item.prop_snap.address.street_number }}
                            {{ item.prop_snap.address.street_name }}
                            {{ item.prop_snap.address.street_type }}
                            {{ item.prop_snap.address.street_direction }}
                            {{ item.prop_snap.address.unit }}
                            {{ item.prop_snap.address.postal_code }}
                            {{ item.prop_snap.address.city }}
                            {{ item.prop_snap.address.province }}
                          </td>

                          <td>
                            {{ item.prop_snap.prop_style.property_type }}
                          </td>

                          <td>
                            {{ item.prop_snap.purchase_price | currency }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Liability -->
        <v-expansion-panel class="bordered">
          <v-expansion-panel-header> Liability </v-expansion-panel-header>

          <v-expansion-panel-content>
            <p class="grey--text mt-5">Liabilities</p>
            <v-card
              v-for="int_cust in item.int_custs"
              :key="int_cust.id"
              class="bordered mb-2"
            >
              <v-card-title>
                <div class="text-subtitle-2">
                  {{ int_cust.cust_snap.first_name }}
                  {{ int_cust.cust_snap.middle_name }}
                  {{ int_cust.cust_snap.last_name }}
                </div>
              </v-card-title>

              <v-card-text>
                <v-simple-table>
                  <thead class="text-caption">
                    <tr>
                      <th class="py-2">Single or Joint</th>

                      <th>Type</th>

                      <th>Balance</th>

                      <th>Payment</th>

                      <th>Maturity Date</th>

                      <th :width="350">Pay Off</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="int_liab in int_cust.int_liabs"
                      :key="int_liab.id"
                    >
                      <td>
                        {{ int_liab.cust_liab.single ? "Joint" : "Single" }}
                      </td>

                      <td>
                        {{ int_liab.cust_liab.liability_type }}
                      </td>

                      <td>
                        {{ int_liab.cust_liab.balance | currency }}
                      </td>

                      <td>
                        {{ int_liab.cust_liab.payment | currency }}
                      </td>

                      <td>
                        {{ int_liab.cust_liab.maturity_date | formatDate }}
                      </td>

                      <td>
                        {{ int_liab.payoff }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>

            <p class="grey--text mt-5">Existing Mortgage</p>
            <v-card
              v-for="int_prop in item.int_props"
              :key="int_prop.id"
              class="bordered mb-2"
            >
              <v-card-title class="text-subtitle-2">
                {{ int_prop.prop_snap.address.street_number }}
                {{ int_prop.prop_snap.address.street_name }}
                {{ int_prop.prop_snap.address.street_type }}
                {{ int_prop.prop_snap.address.street_direction }}
                {{ int_prop.prop_snap.address.unit }}
                {{ int_prop.prop_snap.address.postal_code }}
                {{ int_prop.prop_snap.address.city }}
                {{ int_prop.prop_snap.address.province }}
              </v-card-title>

              <v-card-text>
                <p class="mt-5 grey--text">
                  <span v-if="int_prop.int_mtgs.length == 0">
                    (no existing mortgages found)
                  </span>
                </p>

                <v-simple-table v-if="int_prop.int_mtgs.length !== 0">
                  <thead class="text-caption">
                    <tr>
                      <th class="py-2">Position</th>

                      <th>Lender</th>

                      <th>Balance Remaining</th>

                      <th>Maturity Date</th>

                      <th :width="350">Payoff</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="int_mtg in int_prop.int_mtgs"
                      :key="int_mtg.id"
                      class="bordered"
                    >
                      <td>
                        {{ int_mtg.prop_mort.position }}
                      </td>

                      <td>
                        {{ int_mtg.prop_mort.lender }}
                      </td>

                      <td>
                        {{ int_mtg.prop_mort.balance_remaining | currency }}
                      </td>

                      <td>
                        {{ int_mtg.prop_mort.maturity_date | formatDate }}
                      </td>

                      <td>
                        {{ int_mtg.payoff }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Lawyers -->
        <v-expansion-panel class="bordered">
          <v-expansion-panel-header> Lawyers </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-expansion-panels multiple>
              <!-- lawyers for the borrower -->
              <v-expansion-panel class="bordered">
                <v-expansion-panel-header> Borrowers </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>
                  <h3
                    v-if="
                      item.int_lyrs
                        ? item.int_lyrs.filter((el) => {
                            return el.type === 'borrower' && !el.archived;
                          }).length === 0
                        : false
                    "
                    class="mt-10 grey--text text-center"
                  >
                    No Lawyers For The Borrower
                  </h3>

                  <v-expansion-panels class="mt-5" multiple>
                    <v-expansion-panel
                      class="bordered"
                      v-for="int_lyr in item.int_lyrs
                        ? item.int_lyrs.filter((el) => {
                            return el.type === 'borrower' && !el.archived;
                          })
                        : []"
                      :key="int_lyr.id"
                    >
                      <v-expansion-panel-header>
                        <div class="d-flex align-center">
                          {{
                            int_lyr.user.companies
                              .map((company) => company.name)
                              .join(", ")
                          }}
                          -
                          {{ int_lyr.user.first_name }}
                          {{ int_lyr.user.last_name }}
                          {{ int_lyr.active ? " (Active)" : "" }}
                        </div>
                      </v-expansion-panel-header>

                      <v-divider />

                      <v-expansion-panel-content>
                        <v-simple-table class="mt-5">
                          <tbody>
                            <tr>
                              <td>Primary Email</td>

                              <td>
                                {{ int_lyr.user.email }}
                              </td>
                            </tr>

                            <tr
                              v-for="contact_info in int_lyr.user.contact_infos"
                              :key="contact_info.id"
                            >
                              <td>{{ contact_info.type }}</td>

                              <td>
                                {{
                                  contact_info.type !== "Email"
                                    ? contact_info.number
                                    : contact_info.email
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <!-- list of notes -->

                        <v-data-table
                          class="mt-5"
                          :headers="lawyer_note_headers"
                          :items="int_lyr.notes"
                          :items-per-page="5"
                        />
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- lawyers for the lender -->
              <v-expansion-panel class="bordered">
                <v-expansion-panel-header> Lender </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>
                  <h3
                    v-if="
                      item.int_lyrs
                        ? item.int_lyrs.filter((el) => {
                            return el.type === 'lender' && !el.archived;
                          }).length === 0
                        : false
                    "
                    class="mt-10 grey--text text-center"
                  >
                    No Lawyers For The Lender
                  </h3>

                  <v-expansion-panels class="mt-5" multiple>
                    <v-expansion-panel
                      class="bordered"
                      v-for="int_lyr in item.int_lyrs
                        ? item.int_lyrs.filter((el) => {
                            return el.type === 'lender' && !el.archived;
                          })
                        : []"
                      :key="int_lyr.id"
                    >
                      <v-expansion-panel-header>
                        <div class="d-flex align-center">
                          {{
                            int_lyr.user.companies
                              .map((company) => company.name)
                              .join(", ")
                          }}
                          -
                          {{ int_lyr.user.first_name }}
                          {{ int_lyr.user.last_name }}
                          {{ int_lyr.active ? " (Active)" : "" }}
                        </div>
                      </v-expansion-panel-header>

                      <v-divider />

                      <v-expansion-panel-content>
                        <v-simple-table class="mt-5">
                          <tbody>
                            <tr>
                              <td>Primary Email</td>

                              <td>
                                {{ int_lyr.user.email }}
                              </td>
                            </tr>

                            <tr
                              v-for="contact_info in int_lyr.user.contact_infos"
                              :key="contact_info.id"
                            >
                              <td>{{ contact_info.type }}</td>

                              <td>
                                {{
                                  contact_info.type !== "Email"
                                    ? contact_info.number
                                    : contact_info.email
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <!-- list of notes -->

                        <v-data-table
                          class="mt-5"
                          :headers="lawyer_note_headers"
                          :items="int_lyr.notes"
                          :items-per-page="5"
                        />
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Loan Information -->
        <v-expansion-panel class="bordered">
          <v-expansion-panel-header>
            Loan Information
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td :width="350">Value</td>
                    <td>{{ item.value | currency }}</td>
                  </tr>

                  <tr>
                    <td>Mortgage Position</td>
                    <td>{{ item.mortgage_position | mortgagePosition }}</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td colspan="2" class="grey--text">Loan Terms:</td>
                  </tr>

                  <tr>
                    <td>Term (Months)</td>
                    <td>{{ item.term_months }}</td>
                  </tr>

                  <tr>
                    <td>LTV</td>
                    <td>{{ item.ltv }}</td>
                  </tr>

                  <tr>
                    <td>Interest Rate</td>
                    <td>{{ item.interest_rate }}</td>
                  </tr>

                  <tr>
                    <td>Interest Amount</td>
                    <td>{{ item.interest_amount | currency }}</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td colspan="2" class="grey--text">Debt Service Ratios:</td>
                  </tr>

                  <tr>
                    <td>Gross Debt Service Ratio (GDS)</td>
                    <td>{{ item.gds }}</td>
                  </tr>

                  <tr>
                    <td>Total Debt Service Ratio (TDS)</td>
                    <td>{{ item.tds }}</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td colspan="2" class="grey--text">APR:</td>
                  </tr>

                  <tr>
                    <td>APR in Disclosure</td>
                    <td>{{ item.apr_in_disclosure }}</td>
                  </tr>

                  <tr>
                    <td>APR in Formula</td>
                    <td>{{ item.apr_in_formula }}</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td colspan="2" class="grey--text">Mortgage Dates:</td>
                  </tr>

                  <tr>
                    <td>Mortgage Start Date</td>
                    <td>{{ item.mort_start_date | formatDate }}</td>
                  </tr>

                  <tr>
                    <td>Mortgage Maturity Date</td>
                    <td>{{ item.mort_maturity_date | formatDate }}</td>
                  </tr>

                  <tr>
                    <td>First Payment Date</td>
                    <td>{{ item.first_payment_date | formatDate }}</td>
                  </tr>

                  <tr>
                    <td>Last Payment Date</td>
                    <td>{{ item.last_payment_date | formatDate }}</td>
                  </tr>

                  <tr>
                    <td>Interest Adjustment</td>
                    <td>{{ item.interest_adjustment }}</td>
                  </tr>

                  <tr>
                    <td>Interest Adjustment Date</td>
                    <td>{{ item.interest_adjustment_date | formatDate }}</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td colspan="2" class="grey--text">Loan Type:</td>
                  </tr>

                  <tr>
                    <td>Loan Type</td>
                    <td>{{ item.loan_type }}</td>
                  </tr>

                  <tr>
                    <td>Mortgage Type</td>
                    <td>{{ item.mortgage_type }}</td>
                  </tr>

                  <tr>
                    <td>Ratio</td>
                    <td>{{ item.ratio }}</td>
                  </tr>

                  <tr>
                    <td>Classification</td>
                    <td>{{ item.classification }}</td>
                  </tr>

                  <tr>
                    <td>Include in TDS</td>
                    <td>{{ item.include_in_tds }}</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td colspan="2" class="grey--text">Other:</td>
                  </tr>

                  <tr>
                    <td>Alert</td>
                    <td>{{ item.alert }}</td>
                  </tr>

                  <tr>
                    <td>Amortization Interest</td>
                    <td>{{ item.amortization_interest }}</td>
                  </tr>

                  <tr v-if="item.amortization_interest === 'amortization'">
                    <td>Amortization Years</td>
                    <td>{{ item.amortization_months }}</td>
                  </tr>

                  <tr>
                    <td>NSF Fee Amount</td>
                    <td>{{ item.nsf_fee_amount | currency }}</td>
                  </tr>

                  <tr>
                    <td>NSF Fee Notes</td>
                    <td>
                      <span
                        v-for="note in item.notes.filter(
                          (el) => el.type === 'NSF Fee Note'
                        )"
                        :key="note.id"
                      >
                        <div>• {{ note.note }}</div>
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>Prepayment Penalty</td>
                    <td>{{ item.prepayment_penalty }}</td>
                  </tr>

                  <tr>
                    <td>Prepayment Penalty Number of Months</td>
                    <td>{{ item.prepayment_penalty_number_of_months }}</td>
                  </tr>

                  <tr>
                    <td>Payment Amount</td>
                    <td>{{ item.payment_amount | currency }}</td>
                  </tr>

                  <tr>
                    <td>Payment Frequency</td>
                    <td>{{ item.payment_frequency }}</td>
                  </tr>

                  <tr v-if="item.payment_frequency === 'Other'">
                    <td>Specified Payment Frequency</td>
                    <td>{{ item.specify_payment_frequency }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Status -->
        <!-- <v-expansion-panel class="bordered">
          <v-expansion-panel-header> Status </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td :width="350">Status</td>
                    <td>{{ item.status | capitalize_first_letter }}</td>
                  </tr>

                  <tr
                    v-for="(note, index_note) in item.notes.filter(
                      (el) => el.type === 'Funded Mortgage Status'
                    )"
                    :key="note.id"
                  >
                    <td>
                      {{ index_note === 0 ? "Notes" : "" }}
                    </td>
                    <td>{{ note.note }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel> -->

        <!-- Holdbacks -->
        <v-expansion-panel class="bordered">
          <v-expansion-panel-header> Holdbacks </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-data-table
              v-if="item.holdbacks.length > 0"
              :headers="holdback_header"
              :items="item.holdbacks"
              :items-per-page="5"
            >
              <template v-slot:item.amount="{ item }">
                {{ item.amount | currency }}
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Condition -->
        <v-expansion-panel class="bordered">
          <v-expansion-panel-header> Condition </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="condition in item.fund_mort_conds"
                    :key="condition.id"
                  >
                    <td class="pl-5">• {{ condition.condition }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Lender Information -->
        <v-expansion-panel class="bordered">
          <v-expansion-panel-header>
            Lender Information
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-row class="mt-5">
              <v-col>
                <v-row v-if="item.int_accounts.length > 0">
                  <v-col>
                    <p class="grey--text">Selected Accounts:</p>

                    <v-simple-table class="mb-5">
                      <thead class="text-caption">
                        <tr>
                          <th>Company Name</th>

                          <th>Institution</th>

                          <th>Type</th>

                          <th>Corporation Name</th>

                          <th>Account Number</th>

                          <th :width="150">Percentage</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(int_account, index) in item.int_accounts"
                          :key="int_account.account.id"
                        >
                          <td>
                            {{ int_account.account.company.name }}
                          </td>

                          <td>
                            {{ int_account.account.institution }}
                          </td>

                          <td>
                            {{ int_account.account.type }}
                          </td>

                          <td>
                            {{ int_account.account.corporation_name }}
                          </td>

                          <td>
                            {{ int_account.account.account_number }}
                          </td>

                          <td>
                            {{ int_account.percentage }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>

                    <p
                      :class="
                        item.int_accounts.reduce(
                          (acc, curr) => acc + Number(curr.percentage),
                          0
                        ) > 100
                          ? 'red--text text-end'
                          : 'white--text text-end'
                      "
                    >
                      Total Percentage:
                      {{
                        item.int_accounts.reduce(
                          (acc, curr) => acc + Number(curr.percentage),
                          0
                        ) > 100
                          ? "Cannot be greater than 100"
                          : item.int_accounts.reduce(
                              (acc, curr) => acc + Number(curr.percentage),
                              0
                            )
                      }}
                      %
                    </p>
                  </v-col>
                </v-row>

                <v-row v-if="item.int_account_notes.length > 0">
                  <v-col>
                    <v-data-table
                      :headers="note_header"
                      :items="item.int_account_notes"
                      :items-per-page="5"
                    >
                      <template v-slot:item.createdAt="{ item }">
                        {{ item.createdAt | formatDate }}
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Fees -->
        <v-expansion-panel class="bordered">
          <v-expansion-panel-header> Fees </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-data-table
              :headers="fees_header"
              :items="item.fund_mort_fees"
              :items-per-page="5"
            >
              <template v-slot:item.included_in_APR="{ item }">
                {{ item.included_in_APR | yes_no }}
              </template>

              <template v-slot:item.type="{ item }">
                {{ item.type === "others" ? item.specify_others : item.type }}
              </template>

              <template v-slot:item.amount="{ item }">
                {{ item.amount | currency }}
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Prepaid Interest -->
        <v-expansion-panel class="bordered">
          <v-expansion-panel-header>
            Prepaid Interest
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-data-table
              v-if="item.prep_ints.length > 0"
              :headers="prep_int_header"
              :items="item.prep_ints"
              :items-per-page="5"
            >
              <template v-slot:[`item.length`]="{ item }">
                {{ item.length }}
                {{ item.length > 1 ? "months" : "month" }}
              </template>

              <template v-slot:[`item.start_date`]="{ item }">
                {{ item.start_date | formatDate }}
              </template>

              <template v-slot:[`item.end_date`]="{ item }">
                {{ item.end_date | formatDate }}
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Payment Method -->
        <v-expansion-panel class="bordered">
          <v-expansion-panel-header> Payment Methods </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-data-table
              :headers="payment_method_header"
              :items="item.pay_mtds"
              :items-per-page="5"
            >
              <template v-slot:item.name="{ item }">
                {{ item.name | capitalize_first_letter }}
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Referral Information -->
        <v-expansion-panel class="bordered">
          <v-expansion-panel-header>
            Referral Information
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td :width="350">Referral</td>
                    <td>
                      {{ item.with_referral | yes_no }}
                    </td>
                  </tr>

                  <tr
                    v-for="(note, index_note) in item.notes.filter(
                      (el) => el.type === 'Referral Note'
                    )"
                    :key="note.id"
                  >
                    <td>
                      {{ index_note === 0 ? "Notes" : "" }}
                    </td>
                    <td>{{ note.note }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Closing/Funding Information -->
        <v-expansion-panel class="bordered">
          <v-expansion-panel-header>
            Closing/Funding Information
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td :width="350">Date Close/Funded</td>
                    <td>
                      {{ item.date_closed | formatDate }}
                    </td>
                  </tr>

                  <tr>
                    <td>Actual Fund Date</td>
                    <td>
                      {{ item.actual_fund_date | formatDate }}
                    </td>
                  </tr>

                  <tr>
                    <td>Reporting letter information, did it differ?</td>
                    <td>
                      {{ item.reporting_letter_information }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Reimbursement -->
        <v-expansion-panel class="bordered">
          <v-expansion-panel-header> Reimbursement </v-expansion-panel-header>

          <v-expansion-panel-content>
            <p class="text-body-2">Borrower:</p>

            <v-data-table
              :headers="reimbursement_header"
              :items="item.reimbursed.filter((el) => el.type === 'borrower')"
              :items-per-page="5"
            >
              <template v-slot:item.amount="{ item }">
                {{ item.amount | currency }}
              </template>
            </v-data-table>

            <v-divider class="my-5" />

            <p class="text-body-2">Lender:</p>

            <v-data-table
              :headers="reimbursement_header"
              :items="item.reimbursed.filter((el) => el.type === 'lender')"
              :items-per-page="5"
            >
              <template v-slot:item.amount="{ item }">
                {{ item.amount | currency }}
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Notes -->
        <v-expansion-panel class="bordered">
          <v-expansion-panel-header> Notes </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-card
              style="background: transparent"
              ref="fund_mort_note_list"
              flat
            >
              <p class="mb-0 grey--text font-weight-medium">
                Click on the text field to input your notes, you can also add
                screenshots or image files by copy/paste or drag and drop into
                the text field below.
              </p>

              <v-card-text>
                <p
                  v-if="item.notes.length === 0"
                  class="text-center grey--text text-subtitle-1"
                >
                  No notes available. Make a note below.
                </p>

                <div
                  v-for="note in item.notes.filter(
                    (el) => el.type === 'Funded Mortgage Note'
                  )"
                  :key="note.id"
                  class="my-2"
                >
                  <v-card class="rounded-lg">
                    <v-card-text>
                      <p class="d-flex align-center mb-0">
                        <span>
                          <v-icon class="text-body-1 mr-1">
                            mdi-account-outline
                          </v-icon>
                          {{ note.user ? note.user.first_name : "" }}
                          {{ note.user ? note.user.last_name : "" }}
                        </span>

                        <span class="ml-auto">
                          <v-icon class="text-body-1 mr-1">
                            mdi-calendar-outline
                          </v-icon>
                          {{ note.createdAt | formatDate }}
                        </span>
                      </p>
                    </v-card-text>

                    <v-divider />

                    <v-card-text>
                      <div v-if="note.note" style="white-space: pre">
                        {{ note.note }}
                      </div>

                      <div
                        v-if="note.content"
                        class="note-contents"
                        v-html="note.content"
                        ref="noteContents"
                      ></div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-card-text>
            </v-card>

            <v-dialog v-model="fund_mort_note_dialog" fullscreen>
              <v-toolbar class="sticky-toolbar orange darken-3" flat>
                <v-toolbar-title>
                  <v-icon>mdi-image</v-icon> Image Preview
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  @click="fund_mort_note_dialog = false"
                  title="Close"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>

              <v-card>
                <v-card-text>
                  <v-img :src="selectedFundMortImage" aspect-ratio="1" />
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import filt from "@/plugins/filters";

export default {
  props: ["item"],

  data: () => ({
    fund_mort_note_dialog: false,
    selectedFundMortImage: "",

    fund_mort_panels: [14],

    account_header: [
      { text: "Type", value: "type" },
      { text: "Institution", value: "institution" },
      { text: "Account Number", value: "account_number" },
      { text: "Corporation Name", value: "corporation_name" },
    ],

    fees_header: [
      { text: "Included in APR", value: "included_in_APR" },
      { text: "Type", value: "type" },
      { text: "Amount", value: "amount" },
      { text: "Note", value: "note" },
    ],

    payment_method_header: [
      { text: "Name", value: "name" },
      { text: "Note", value: "note" },
    ],

    reimbursement_header: [
      { text: "description", value: "description" },
      { text: "Amount", value: "amount" },
      { text: "Notes", value: "note" },
    ],

    lawyer_note_headers: [{ text: "Notes", value: "note" }],

    note_header: [
      { text: "Note", value: "note" },
      { text: "Date", value: "createdAt" },
    ],

    holdback_header: [
      { text: "Amount", value: "amount" },
      { text: "Description", value: "description" },
      { text: "Notes", value: "note" },
    ],

    prep_int_header: [
      { text: "Amount", value: "amount" },
      { text: "Length (in month)", value: "length" },
      { text: "Start Date", value: "start_date" },
      { text: "End Date", value: "end_date" },
      { text: "Note", value: "note" },
    ],
  }),

  methods: {
    imgClick(e) {
      if (e.target.tagName === "IMG") {
        this.selectedFundMortImage = e.target.src;
        this.fund_mort_note_dialog = true;
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$refs.fund_mort_note_list) {
        this.$refs.fund_mort_note_list.$el.addEventListener(
          "click",
          this.imgClick.bind(this)
        );
      }
    });
  },

  filters: {
    ...filt,
  },
};
</script>

<style scoped>
.td-right-border {
  border-right: 1px solid #333;
}

.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}
</style>
